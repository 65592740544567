.legal-page{
    padding: 50px;
    min-height: 90vh;
}
h1{
    font-size: 2.8rem;
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
}
.container{
    margin-bottom: 10px;
}
.legal-page h2{
    font-size: 1.4rem;
    font-weight: 400;
    text-decoration: underline;
}
p{
    font-size: 1.2rem;
}