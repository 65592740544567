.contact-page *{
    box-sizing: border-box;
}
.contact-page{
    font-family: 'Chakra Petch', sans-serif;
    padding: 85px 0;
    color: #636363;
    min-height: 83vh;
}
.form-container{
    width: 80%;
    margin: 0 auto;
    background: #D7D8DD;
    border-radius: 70px 0 70px 70px;
    padding: 30px;
}
h1{
    font-size: 2.4rem;
}
.input-style{
    background-color: #B5B5B5;
    color: #636363;
    border-radius: 10px;
    border: transparent;
    padding: 15px;
    font-size: 1.1rem;
    margin-bottom: 30px;
    font-family: 'Chakra Petch', sans-serif;
}
.form{
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
.user-contact{
    display: flex;
    flex-direction: column;
}
.user-contact .input-style{
    flex-basis: 100%;
}
.message-container .input-style{
    width: 100%;
}
.message-container textarea{
    resize: none;
}
.submit-btn{
    width: 70%;
    font-size: 1.8rem;
    color: #fff;
    background-color: #FF534F;
    border-radius: 38px;
    border: transparent;
    padding: 10px 0;
    cursor: pointer;
    box-shadow: 0 5px 0px #00000040;
}


@media screen and (min-width: 768px) {
    .form-container{
        width: 700px;
        padding: 50px;
    }
    .user-contact{
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .user-contact .input-style{
        flex-basis: 42%;
    }
    .form{
        width: 80%;
    }
}