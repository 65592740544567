.home-page *{
    box-sizing: border-box;
}
.home-page{
    min-height: 91vh;
    padding: 60px 0 35px;
}
/* header */
.home-page header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    padding: 10px 50px;
    background-color: #3E4157;
    clip-path: polygon(0 0,100% 0, 100% 100%, 3% 100%);
}
.home-page h1{
    font-size: 3rem;
    font-weight: 500;
    margin: 0;
}
.link-btn{
    display: inline-block;
    text-decoration: none;
    color: #fff;
    font-size: 2rem;
    background-color: #FF534F;
    border-radius: 21px;
    padding: 0 35px;
    box-shadow: 0 5px 0px #00000040;
    cursor: pointer;
}
/* main */
.main-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    margin: 40px auto;
}
.text-content, .hero-content{
    flex-basis: 50%;
    text-align: center;
}
.text-content .start{
    font-size: 7rem;
    font-weight: 400;
}
.text-content .home-path{
    width: 170px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 1.9rem;
    margin-top: -40px;
}
.marketing{
    margin-top: 40px;
}
.text-content h2{
    font-size: 2.2rem;
    font-weight: 400;
    margin-bottom: 40px;
}
.text-content p{
    font-size: 1.65rem;
    font-weight: 400;
}
.hero-content{
    position: relative;
}
.hero-img{
    border: 8px solid #fff;
    border-radius: 50%;
    width: 80%;
}
.play-icon{
    position: absolute;
    top: 30%;
    left: 38%;
    width: 30%;
    cursor: pointer;
}
/* footer */
footer{
    display: flex;
    width: 95%;
    margin: 0 auto;
    justify-content: space-between;
}
footer .mail-contact{
    color: #9A9797;
}
footer .link-legal{
    font-size: 1.3rem;
    text-decoration: none;
    color: #fff;
}


@media screen and (min-width: 992px) {
    .home-page header{
        flex-direction: row;
    }
    .main-content{
        flex-direction: row;
        width: 90%;
        margin-left: auto;
        padding: 50px 70px 50px 0;
    }
}
@media screen and (min-width: 1600px) {
    .hero-img{
        width: 550px;
    }
    .play-icon{
        width: 200px;
        left: 40%;
    }
}